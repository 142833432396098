<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code title="Commission Report">
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr class="form-control" value="" placeholder="Select Date" :config="{
                            dateFormat: 'd/m/Y',
                            mode: 'range',
                          }" @input="getdata($event)" style="background-color: transparent" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data1" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import Table from '@/components/Table.vue'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import ReportTable from '../../../components/ReportTable.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable
  },
  data () {
    return {
      data: [],
      startdate: '',
      enddate: '',
      result: '',
      data1: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem('accessToken'),
      fields: [
        { field: 'id', label: 'S.R. No', hidden: true, filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'clientname', label: 'Customer', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'plot', label: 'Plot', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'project', label: 'Project', type: 'dropdown', url: 'getProject', responseValue: 'projectname', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'team', label: 'Channel Partners/Franchise/Employee ', type: 'dropdown', url: 'getUsers', responseValue: 'name', filterOptions: { enabled: true, placeholder: 'Search' } },
        // { field: 'employee', label: 'Employee', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'sales_id', label: 'Sales Id', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'bookingdate', label: 'Sales Date Time', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'receiptid', label: 'Receipt Id', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'receiptdate', label: 'Receipt Date Time', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'amount', label: 'Received Amount', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'transitionno', label: 'Transition No', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'bankname', label: 'Bank Name', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'accountno', label: 'Bank Account No.', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'percentage', label: 'Commission %', filterOptions: { enabled: true, placeholder: 'Search' } },
        { field: 'commissionamount', label: 'Commission Amount', filterOptions: { enabled: true, placeholder: 'Search' } }
      ]
    }
  },
  methods: {
    getdata (selectedDates) {
      this.result = selectedDates.split(' to ')
      this.startdate = this.result[0]
      this.enddate = this.result[1]
    },
    async searchData (tableData) {
      const data = {
        startdate: this.startdate,
        enddate: this.enddate
      }
      await axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/receiptcommissionReport`
      })
        .then((response) => {
          this.daataa = []
          this.dataaa = response.data.data
          this.dataaa.map((item) => {
            // item.receipt.clientname
            const data = {
              clientname: item.receipt.clientname,
              plot: item.receipt.sales.plotno,
              project: item.receipt.sales.project,
              team: item.receipt.sales.team,
              sales_id: item.receipt.sales_id,
              bookingdate: item.receipt.sales.bookingdate,
              receiptid: item.receipt.id,
              receiptdate: item.receipt.receiptdate,
              amount: item.amount,
              bankname: item.receipt.bankname,
              accountno: item.receipt.accountno,
              percentage: item.percentage,
              commissionamount: item.receipt.amount
            }
            this.data1.push(data)
          })
          (this.data1, 'data1--==')
          this.fields.map(async (item) => {
            if (item.type == 'dropdown') {
              const accessToken = localStorage.getItem('accessToken')
              const baseApi = process.env.VUE_APP_APIENDPOINT
              const requestOptionsUsers = {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                url: `${baseApi}/${item.url}`
              }

              await axios(requestOptionsUsers)
                .then((response) => {
                  response.data.data.data
                    ? (this.data[item.url] = response.data.data.data)
                    : response.data.data
                      ? (this.data[item.url] = response.data.data)
                      : (this.data[item.url] = response.data)
                })
              this.data1.map((data1) => {
                this.data[item.url].map((data) => {
                  if (data1[item.field] == data.id) {
                    data1[item.field] = data[item.responseValue]
                  }
                })
              })
                .catch((error) => console.log(error, 'error'))
            }
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
